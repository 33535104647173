<template>
  <div>
    <div class=" tw-z-0">
      <div class="case-study">
        <div class="case-study__header">
         <div class="landing_wrapper">
            <Navbar />
          </div>
          <div class="container tw-z-10 tw-relative">
            <div class="lg:tw-px-12 lg:tw-mx-12 tw-z-0 tw-relative">
              <div
                class="tw-mt-[145px] tw-text-[50px] tw-text-[#1b1b1b] tw-font-bold tw-text-center tw-mx-auto lg:tw-max-w-[100%] tw-max-w-[300px]"
              >
                {{ $t('caseStudy.title') }}
              </div>
              <div
                  class="tw-flex lg:tw-hidden tw-flex-col lg:tw-flex-row tw-justify-center tw-mx-auto tw-items-center"
                >
                  <div
                    class="tw-w-[160px] tw-h-[98px] tw-my-8 tw-bg-white tw-drop-shadow tw-flex tw-justify-center tw-items-center tw-rounded-lg"
                  >
                    <img
                      class="tw-w-[ 123px] tw-h-[32px]"
                      src="@/assets/images/caseStudy/logo-aanaaab.png"
                      alt=""
                    />
                  </div>
                  <div
                    class="tw-mx-5 tw-text-[#292929] lg:tw-text-start tw-text-center tw-text-[30px] tw-font-semibold"
                  >
                    <div
                      class="tw-text-2xl tw-mt-4 lg:tw-mt-0 tw-text-[#292929]"
                    >
                       {{ $t('caseStudy.aanaab') }}
                    </div>
                    <p class="tw-text-[#808285] lg:tw-text-[21px] tw-text-base">
                       {{ $t('caseStudy.title1') }}
                    </p>
                  </div>
                </div>
              <div class="tw-w-full tw-m-auto">
                <img
                  class="tw-h-auto tw-mt-[55px] tw-object-cover"
                  src="@/assets/images/caseStudy/home/aanaab-mockup-3x.png"
                  srcset="
                    @/assets/images/caseStudy/home/aanaab-mockup.png 480w,
                    @/assets/images/caseStudy/home/aanaab-mockup-3x.png 1024w
                  "
                  alt="Aanaab poster"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="lg:tw-px-12 lg:tw-mx-52 tw-z-0 tw-relative">
            <div class="">
              <div class="tw-flex tw-justify-between tw-mt-[84px]">
                <div
                  class="tw-hidden lg:tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-mx-0 tw-items-center "
                >
                  <div
                    class="tw-w-[160px] tw-h-[98px] tw-bg-white tw-drop-shadow tw-flex tw-justify-center tw-items-center tw-rounded-lg"
                  >
                    <img
                      class="tw-w-[ 123px] tw-h-[32px]"
                      src="@/assets/images/caseStudy/logo-aanaaab.png"
                      alt=""
                    />
                  </div>
                  <div
                    class="tw-mx-5 tw-text-[#292929] lg:tw-text-start tw-text-center tw-text-[30px] tw-font-semibold"
                  >
                    <div
                      class="tw-text-2xl tw-mt-4 lg:tw-mt-0 tw-text-[#292929]"
                    >
                       {{ $t('caseStudy.aanaab') }}
                    </div>
                    <p class="tw-text-[#808285] lg:tw-text-[21px] tw-text-base">
                       {{ $t('caseStudy.title1') }}
                    </p>
                  </div>
                </div>

                <div class="tw-hidden">
                  <div class="tw-text-[18px] tw-text-right tw-font-semibold">
                    Share this article
                  </div>
                  <div class="tw-flex tw-justify-between tw-mt-[15px]">
                    <div
                      class="tw-w-[48px] tw-h-[48px] tw-border-2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-1"
                    >
                      <img
                        class="tw-w-[16px] tw-h-[16px]"
                        src="@/assets/images/caseStudy/home/ic_icon-2.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[48px] tw-h-[48px] tw-border-2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-1"
                    >
                      <img
                        class="tw-w-[16px] tw-h-[16px]"
                        src="@/assets/images/caseStudy/home/facebook.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[48px] tw-h-[48px] tw-border-2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-1"
                    >
                      <img
                        class="tw-w-[16px] tw-h-[16px]"
                        src="@/assets/images/caseStudy/home/twitter.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[48px] tw-h-[48px] tw-border-2 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-1"
                    >
                      <img
                        class="tw-w-[16px] tw-h-[16px]"
                        src="@/assets/images/caseStudy/home/instagram.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-flex-col">
                <div
                  class="lg:tw-mt-[94px] tw-mt-0 tw-text-[36px] tw-font-semibold tw-leading-[3rem] tw-text-start tw-text-[#333333]"
                >
                 
                   {{ $t('caseStudy.title2') }}
                </div>
                <div
                  class="tw-text-[#373a3b] tw-text-[16px] tw-font-medium tw-mt-[13px]"
                >
                  
                   {{ $t('caseStudy.p') }}
                </div>
                <p class="tw-text-[#1e1e1e] tw-text-[18px]">
                 
                   {{ $t('caseStudy.p1') }}
                </p>
                <div class="tw-mt-[5px]">
                   {{ $t('caseStudy.p2') }}
                </div>
                <p class="tw-text-[#1e1e1e] tw-text-[18px] tw-mt-[21px]">
                 
                   <a href="https://aanaab.com/" target="_blank" class=" tw-text-[#0d6efd] tw-underline " >{{ $t('caseStudy.aanaab') }}</a> {{ $t('caseStudy.p3') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="case-study__center-header">
          <div class="container">
            <div class="lg:tw-px-12 lg:tw-mx-52 tw-z-20 tw-relative">
              <div>
                <div
                  class="tw-mt-[55px] tw-text-[36px] tw-tw-font-semibold tw-text-[#333333]"
                >
                   {{ $t('caseStudy.title3') }}
                </div>
                <p class="tw-text-[#1e1e1e] tw-text-[18px] tw-mt-[14px]">
                 
                   {{ $t('caseStudy.p4') }}
                </p>
                <p class="tw-text-[#1e1e1e] tw-text-[18px] tw-mt-[14px]">
                 
                   {{ $t('caseStudy.p5') }}
                </p>
                <div
                  class="tw-mt-[30px] tw-text-[36px] tw-font-semibold tw-text-[#333333]"
                >
                 
                   {{ $t('caseStudy.title4') }}
                </div>
                <div
                  class="tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-6 tw-mt-9"
                >
                  <div
                    class=" tw-flex tw-justify-start tw-bg-gray-100 tw-items-center tw-rounded-xl tw-px-3 tw-shadow-sm case-study__center-header-card"
                  >
                    <div
                      class="tw-w-[70px] tw-h-[70px] tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-mx-2"
                    >
                      <img
                        class="tw-w-[25px] tw-h-[28px]"
                        src="@/assets/images/caseStudy/home/ic_creative-team.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[248px] tw-text-[18px] tw-font-semibold tw-leading-8 tw-mx-5"
                    >
                      
                       {{ $t('caseStudy.expansion') }}
                    </div>
                  </div>
                  <div
                    class=" tw-flex tw-justify-start tw-bg-gray-100 tw-items-center tw-rounded-xl tw-px-3 tw-shadow-sm case-study__center-header-card"
                  >
                    <div
                      class="tw-w-[70px] tw-h-[70px] tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-mx-2"
                    >
                      <img
                        class="tw-w-[25px] tw-h-[28px]"
                        src="@/assets/images/caseStudy/home/ic_squad.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[248px] tw-text-[18px] tw-font-semibold tw-leading-8 tw-mx-5"
                    >
                     
                       {{ $t('caseStudy.expansion1') }}
                    </div>
                  </div>
                  <div
                    class=" tw-flex tw-justify-start tw-bg-gray-100 tw-items-center tw-rounded-xl tw-px-3 tw-shadow-sm case-study__center-header-card"
                  >
                    <div
                      class="tw-w-[70px] tw-h-[70px] tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-mx-2"
                    >
                      <img
                        class="tw-w-[25px] tw-h-[28px]"
                        src="@/assets/images/caseStudy/home/layers.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[248px] tw-text-[18px] tw-font-semibold tw-leading-8 tw-mx-5"
                    >
                       {{ $t('caseStudy.expansion2') }}
                    </div>
                  </div>
                  <div
                    class=" tw-flex tw-justify-start tw-bg-gray-100 tw-items-center tw-rounded-xl tw-px-3 tw-shadow-sm case-study__center-header-card"
                  >
                    <div
                      class="tw-w-[70px] tw-h-[70px] tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-mx-2"
                    >
                      <img
                        class="tw-w-[25px] tw-h-[28px]"
                        src="@/assets/images/caseStudy/home/lock.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="tw-w-[248px] tw-text-[18px] tw-font-semibold tw-leading-8 tw-mx-5"
                    >

                       {{ $t('caseStudy.expansion3') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="lg:tw-px-12 lg:tw-mx-52 tw-z-20 tw-relative">
            <div>
              <p class="tw-mt-[73px] tw-text-[#1e1e1e] tw-text-[18px]">
               
                 {{ $t('caseStudy.p6') }}
              </p>
              <div
                class="tw-mt-[29px] tw-text-[28px] tw-tw-font-semibold tw-text-[#333333]"
              >
                 {{ $t('caseStudy.title5') }}
              </div>
              <p class="tw-text-[#1e1e1e] tw-text-[18px]">
                {{ $t('caseStudy.tableTitle') }}
              </p>
              <div class="tw-bg-[#f7f6fa] tw-rounded tw-my-[29px] tw-p-4 lg:tw-p-[48px]">
                <div class="tw-grid tw-mb-8 tw-grid-cols-3 tw-gap-8">
                  <div
                    class="tw-text-[#1b1b1b] lg:tw-text-2xl tw-text-[14px] tw-font-bold"
                  >
                     {{ $t('caseStudy.tableTitle') }}
                  </div>
                  <div
                    class="tw-text-[rgba(255, 255, 255)] tw-min-h-[75px] tw-min-w-[75px] tw-text-center tw-text-[10px] lg:tw-text-lg tw-shadow-sm tw-bg-white tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-justify-center tw-font-semibold"
                  >
                    <span class="tw-block"> {{ $t('caseStudy.before') }}</span> 
                     {{ $t('caseStudy.teamSquadio') }}
                  </div>

                  <div
                    class="tw-bg-[#422e87] tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-shadow-sm"
                  >
                    <img
                      class="tw-h-[20px] lg:tw-h-[40px] tw-object-contain tw-px-2"
                      src="@/assets/images/logo/2Asset 1.svg"
                      alt=""
                    />
                    <div
                      class="tw-text-white tw-font-bold tw-tracking-[1.19px] tw-text-[12px] lg:tw-text-lg tw-text-center"
                    >
                       {{ $t('caseStudy.after') }} 
                       {{ $t('caseStudy.teamSquadio') }}
                    </div>
                  </div>
                </div>
                <table class="tw-table-fixed tw-w-full mt-[33px]">
                  <tbody class="tw-text-center">
                    <tr
                      class="tw-bg-[#eeecf5] tw-h-[45px] tw-text-[17px] tw-font-semibold"
                    >
                      <td
                        class="tw-px-5 tw-text-[10px] lg:tw-text-base tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                         {{ $t('caseStudy.tableRow') }}
                      </td>

                      <td class="tw-text-[10px] lg:tw-text-base" >40K</td>
                      <td class="tw-text-[10px] lg:tw-text-base">150K</td>
                    </tr>
                    <tr class="tw-h-[45px] tw-text-[17px] tw-font-semibold">
                      <td
                        class="tw-px-5 lg:tw-text-base tw-text-[10px] tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                         {{ $t('caseStudy.tableRow1') }}
                      </td>

                      <td class="lg:tw-text-base tw-text-[10px]">30</td>
                      <td class="lg:tw-text-base tw-text-[10px]">100+</td>
                    </tr>
                    <tr
                      class="tw-bg-[#eeecf5] tw-h-[45px] tw-text-[17px] tw-font-semibold"
                    >
                      <td
                        class="tw-px-5 lg:tw-text-base tw-text-[10px] tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                         {{ $t('caseStudy.tableRow2') }}
                         <br/>
                         {{ $t('caseStudy.tableRow2p') }}
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        73K
                      </td>
                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        300K
                      </td>
                    </tr>
                    <tr class="tw-h-[45px] tw-text-[17px] tw-font-semibold">
                      <td
                        class="tw-px-5 tw-text-[10px] lg:tw-text-base tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                         {{ $t('caseStudy.tableRow3') }}
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        7
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        25+
                      </td>
                    </tr>
                    <tr
                      class="tw-bg-[#eeecf5] tw-h-[45px] tw-text-[17px] tw-font-semibold hidden "
                    >
                      <td
                        class="tw-px-5 tw-text-[10px] lg:tw-text-base tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                         {{ $t('caseStudy.tableRow4') }}
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        -
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        130+
                      </td>
                    </tr>
                    <tr class=" tw-h-[45px] tw-text-[17px] tw-font-semibold">
                      <td
                        class="tw-px-5 tw-text-[10px] lg:tw-text-base tw-text-[#1b1b1b] tw-leading-2 tw-font-normal tw-text-start "
                      >
                        
                         {{ $t('caseStudy.tableRow5') }}
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        1
                      </td>

                      <td
                        class="tw-text-[10px] lg:tw-text-base"
                      >
                        6+
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="tw-mt-[70px] tw-text-[28px] tw-font-semibold text-[#333333]"
              >
               
                 {{ $t('caseStudy.title6') }}
              </div>
              <ul class="tw-list-none tw-mt-[70px] list-pr">
                <li class="tw-text-[#373a3b] tw-text-[18px] tw-mt-[17px]">
                 
                  {{ $t('caseStudy.p8First') }} 

                    <a href="https://hundred.org/en" target="_blank" class=" tw-text-[#0d6efd] tw-underline tw-contents" >{{ $t('caseStudy.hudredEd') }}</a> 

                  {{ $t('caseStudy.p8End') }} 
                </li>
                <li class="tw-text-[#373a3b] tw-text-[18px] tw-mt-[17px]">
                
                   {{ $t('caseStudy.p9') }}
                </li>
                <li class="tw-text-[#373a3b] tw-text-[18px] tw-mt-[17px]">
                 
                   {{ $t('caseStudy.p10') }}
                </li>
              </ul>
              <p class="tw-text-[#1e1e1e] tw-mt-[29px] tw-text-[18px] tw-mb-8">
               
                 {{ $t('caseStudy.p11') }}
              </p>
            </div>
          </div>
        </div>
        <div class="landing_wrapper">
          <div class="">
            <Footer :active-tab="'client'" class="landing-footer client" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/pages/Client/caseStady/components/navbar.vue";
import Footer from "@/pages/landing/components/footer/footer.vue";

import "@/pages/landing/landing.scss";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.case-study {
  overflow: hidden;
  padding-bottom: 50px;
  background-color: #fff !important;

  .case-study__center-header {
    background-image: url("../../../assets/images/client/bg_words-shadow-2x.png");
    min-height: 80vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;


  }

  .case-study__center-header-card {
    background-image: url("../../../assets/images/client/bg_words-shadow-2x.png");
    background-position: center;
    background-size: cover;
    padding: 20px;
  }

  .case-study__header {
    position: relative;
    &::before {
      background-image: url("../../../assets/images/caseStudy/case-study-bg-2x.png");
      content: "";
      position: absolute;
      top: 0%;
      right: 0%;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 1255px;
      width: 1255px;

      z-index: 0;

      @media (max-width: 768px) {
        height: 500px;
        width: 500px;
      }
    }
    &:after {
      position: absolute;
      left: -120px;
      top: 30%;
      content: "";
      width: 600px;
      height: 600px;
      background-image: url("../../../assets/images/banner-shape.svg");
      background-position: top;
      background-size: cover;
    }
  }

  .landing_wrapper {
    position: relative;
    z-index: 40;
  }

  .landing_wrapper .topIntro {
    background-color: #fff;

    &:after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  .list-pr li {
    padding-inline-start: 1rem;
    display: flex;
    position: relative;

    &:before {
      content: "";
      height: 7px;
      width: 7px;
      background-color: rgba(66, 46, 135, 0.2);
      border-radius: 2px;
      position: absolute;
      left: 0rem;
      top: 0.6rem;
    }
  }
}
</style>
